import React from 'react'
import SignupForm from '../../containers/signup/SignupForm';
import './signup.css';
const Signup = () => {
  return (
    <div className='bg'>
      <SignupForm/>
    </div>
  )
}

export default Signup